import "../App.css"; // Ensure this file contains the new styles for the landing page
import React, { useState, useEffect } from "react";
import { signInWithPopup } from "firebase/auth";
import { auth, db, googleProvider, facebookProvider } from "../firebase";
import { setDoc, doc, getDoc } from "firebase/firestore";
import { useCaptchaVerification } from "../components/verifyCaptcha";
import LoadingScreen from '../components/LoadingScreen';
import HowItWorks from "../components/HowItWorks";
import { Link } from "react-router-dom";

const LoginPage = () => {
  const [loading, setLoading] = useState(false); // To manage loading state
  const { verified: captchaVerified, error: captchaError } = useCaptchaVerification();

  useEffect(() => {
    if (!captchaVerified) {
      // If the CAPTCHA is not verified, you might want to display a loading indicator
      // or something that tells the user that CAPTCHA is being validated.
      console.log('Verifying CAPTCHA...');
    }
  }, [captchaVerified]);

  if (!captchaVerified) {
    return <LoadingScreen error={captchaError} />;
  }
  // Handle Google Sign-In
  const handleGoogleSignIn = async () => {
    try {
      setLoading(true);

      try {
        // Proceed with Google Sign-In
        const result = await signInWithPopup(auth, googleProvider);
        const user = result.user;

        // Create user data if not already created
        createUserData(user);
        console.log("User info:", user);
      } catch (error) {
        if (error.code === "auth/popup-closed-by-user") {
          alert("The sign-in popup was closed. Please try again.");
        } else {
          console.error("Error during Google sign-in:", error);
          alert("Something went wrong during login.");
        }
      }

    } catch (error) {
      console.error("Error during Google sign-in:", error);
      alert("Something went wrong during login.");
    } finally {
      setLoading(false);
    }
  };

  // Handle the Facebook Sign-in logic
  const handleFacebookSignIn = async () => {
    if (1) {
      alert("Coming soon !")
    }
    else {
      if (!captchaVerified) {
        alert("Please complete the CAPTCHA.");
        return;
      }
      try {
        setLoading(true); // Start loading indicator
        const result = await signInWithPopup(auth, facebookProvider);
        const user = result.user;
        createUserData(user);
        console.log("User info:", user);
        setLoading(false); // Stop loading indicator
      } catch (error) {
        console.error("Error during Facebook sign-in:", error);
        setLoading(false); // Stop loading indicator
      }
    }
  };

  return (
    <div className="flex min-h-screen">
      {/* Left section - Login (30%) */}
      <div className="w-[30%] flex flex-col items-center justify-center p-8 bg-gray-100">
        <div className="w-full max-w-sm space-y-8">
          <div className="text-center">
            <Link to="/" >
              <span className="text-8xl font-bold text-blue-600">meu<span className="text-blue-500">Lar</span></span>
            </Link>
          </div>

          <div className="space-y-4">
            <button
              onClick={handleGoogleSignIn}
              disabled={loading}
              className="w-full flex items-center justify-center gap-3 px-4 py-2 border border-gray-300 rounded-md shadow-sm bg-white text-gray-700 hover:bg-gray-50 transition-colors disabled:opacity-50"
            >
              <img src="google-icon.png" alt="Google" className="w-5 h-5" />
              Login with Google
            </button>

            <button
              onClick={handleFacebookSignIn}
              disabled={loading}
              className="w-full flex items-center justify-center gap-3 px-4 py-2 border border-gray-300 rounded-md shadow-sm bg-white text-gray-700 hover:bg-gray-50 transition-colors disabled:opacity-50"
            >
              <img src="facebook-icon.png" alt="Facebook" className="w-5 h-5" />
              Login with Facebook
            </button>
          </div>
        </div>
      </div>

      {/* Right section - How it Works (70%) */}
      <div className="w-[70%] bg-gray-50 border-r">
        <HowItWorks />
      </div>
    </div>
  );
};

const createUserData = async (user) => {
  const docRef = doc(db, "users", user.uid);
  const docSnapshot = await getDoc(docRef);

  if (!docSnapshot.exists()) {
    await setDoc(doc(db, "users", user.uid), {
      email: user.email,
      createdAt: new Date().toISOString(),
      tokens: { pointsOfInterest: 1, entries: 3 },
    });
    await setDoc(doc(db, "users_entries", user.uid), {
      sliderValues: { Size: 0, Typology: 0, Price: 0, Coziness: 0 },
      stats: { Size: 0, Typology: 0, Price: 0, Coziness: 5 },
      maxs: { Size: 0, Typology: 0, Price: 0, Coziness: 0 },
    });
  }
};

export default LoginPage;
