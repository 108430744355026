
import React from "react";
import { Button } from "./ui/button";
import { Play } from "lucide-react";

const HowItWorks = () => {
  const steps = [
    {
      number: "01",
      title: "Sign Up",
      description: "Create your free account and set up your profile with your preferences.",
    },
    {
      number: "02",
      title: "Add Your Points of Interest",
      description: "Input your workplace, schools, and other important locations to calculate commute times.",
    },
    {
      number: "03",
      title: "Start Discovering",
      description: "Browse personalized listings tailored to your needs and preferences.",
    },
  ];

  return (
    <section className="py-20 bg-white">
      <div className="container px-4 md:px-6">
        <div className="text-center mb-12">
          <h2 className="text-3xl md:text-4xl font-bold text-gray-900 mb-4">
            How It Works
          </h2>
          <p className="text-xl text-gray-600 max-w-2xl mx-auto">
            Finding your dream home is just a few steps away.
          </p>
        </div>
        
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-12">
          {steps.map((step, index) => (
            <div key={index} className="text-center">
              <div className="inline-flex items-center justify-center w-12 h-12 rounded-full bg-blue-600 text-white text-xl font-bold mb-4">
                {step.number}
              </div>
              <h3 className="text-xl font-semibold mb-3 text-gray-900">{step.title}</h3>
              <p className="text-gray-600">{step.description}</p>
            </div>
          ))}
        </div>
        
        {/* Video placeholder */}
        <div className="relative w-full max-w-4xl mx-auto rounded-2xl overflow-hidden shadow-xl mb-12 aspect-video">
          <div className="absolute inset-0 bg-gradient-to-r from-blue-500/20 to-blue-600/10 flex items-center justify-center">
            <div className="absolute inset-0 bg-black/40"></div>
            <button className="relative z-10 rounded-full bg-white/90 p-5 shadow-lg hover:bg-white transition-colors">
              <Play className="h-10 w-10 text-blue-600" />
            </button>
          </div>
        </div>
      
      </div>
    </section>
  );
};

export default HowItWorks;
