
import React from "react";
import { MapPin, Search, BarChart3 } from "lucide-react";

const FeatureCard = ({ icon: Icon, title, description, painPoint }) => {
  return (
    <div className="flex flex-col items-center p-6 bg-white rounded-xl shadow-md transition-all hover:shadow-lg">
      <div className="p-3 bg-blue-100 rounded-full mb-4">
        <Icon className="h-8 w-8 text-blue-600" />
      </div>
      <p className="text-red-500 text-sm font-medium mb-2">{painPoint}</p>
      <h3 className="text-xl font-semibold mb-3 text-gray-900">{title}</h3>
      <p className="text-gray-600 text-center">{description}</p>
    </div>
  );
};

const FeatureSection = () => {
  const features = [
    {
      icon: MapPin,
      painPoint: "TIRED OF LONG COMMUTES?",
      title: "Your Daily Commutes Solved",
      description: "Never get stuck with a home that has a terrible commute. We calculate travel times for each listing to your important destinations.",
    },
    {
      icon: Search,
      painPoint: "OVERWHELMED BY MULTIPLE WEBSITES?",
      title: "All Your Searches in One Place",
      description: "Stop juggling dozens of tabs. Our browser extension brings all your favorite listings into one organized dashboard.",
    },
    {
      icon: BarChart3,
      painPoint: "STRUGGLING TO DECIDE?",
      title: "Make Confident Decisions",
      description: "Our scoring algorithm helps you compare homes based on what actually matters to you, eliminating guesswork and property visit fatigue.",
    },
  ];

  return (
    <section id="features" className="py-20 bg-gray-50">
      <div className="container px-4 md:px-6">
        <div className="text-center mb-12">
          <h2 className="text-3xl md:text-4xl font-bold text-gray-900 mb-4">
            Solutions to Your Home-Finding Challenges
          </h2>
          <p className="text-xl text-gray-600 max-w-2xl mx-auto">
            We've built meuLar to solve the most frustrating parts of finding your next home.
          </p>
        </div>
        
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {features.map((feature, index) => (
            <FeatureCard
              key={index}
              icon={feature.icon}
              painPoint={feature.painPoint}
              title={feature.title}
              description={feature.description}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default FeatureSection;
