
import React from "react";
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from "./ui/card";
import { Button } from "./ui/button";
import { Check } from "lucide-react";

const PricingCard = ({ plan }) => {
  return (
    <Card className={`border ${plan.highlighted ? 'border-blue-600 shadow-lg' : 'border-gray-200'} h-full flex flex-col`}>
      <CardHeader className="text-center">
        <CardTitle className="text-2xl font-bold">{plan.title}</CardTitle>
        <div className="mt-4 mb-2">
          <span className="text-4xl font-bold">{plan.price}</span>
          {plan.price !== "Free" && <span className="text-gray-500 ml-2"></span>}
        </div>
        <CardDescription className="text-gray-500">{plan.description}</CardDescription>
      </CardHeader>
      <CardContent className="flex-grow">
        <ul className="space-y-3">
          {plan.features.map((feature, index) => (
            <li key={index} className="flex items-start">
              <Check className="h-5 w-5 text-blue-600 mr-2 shrink-0 mt-0.5" />
              <span className="text-gray-700">{feature}</span>
            </li>
          ))}
        </ul>
      </CardContent>
      <CardFooter>
        <Button
          onClick={() => window.location.href = '/login'}
          className={`w-full ${plan.highlighted ? 'bg-blue-600 hover:bg-blue-700' : 'bg-gray-100 hover:bg-gray-200 text-gray-900'}`}
          style={{ animationDelay: "200ms" }}
        >
          {plan.buttonText}
        </Button>
      </CardFooter>
    </Card>
  );
};

const PricingSection = () => {
  const plans = [
    {
      title: "Basic",
      price: "Free",
      description: "Perfect for getting started",
      features: [
        "Up to 50 listings",
        "2 points of interest",
        "Basic house scoring",
        "Standard search features"
      ],
      buttonText: "Sign Up",
    },
    {
      title: "Premium",
      price: "$10",
      description: "For extensive searches",
      features: [
        "Up to 100 listings",
        "3 points of interest",
        "Advanced house scoring",
        "Detailed commute analysis",
      ],
      buttonText: "Subscribe",
      highlighted: true,
    },
    {
      title: "Custom",
      price: "$5",
      description: "Add more as you need",
      features: [
        "Additional 100 listings",
        "Additional 3 points of interest",
        "All premium features",
        "Available as add-on to premium plan"
      ],
      buttonText: "Contact Us",
    }
  ];

  return (
    <section id="pricing" className="py-20 bg-white">
      <div className="container px-4 md:px-6">
        <div className="text-center mb-12">
          <h2 className="text-3xl md:text-4xl font-bold text-gray-900 mb-4">
            Transparent Pricing
          </h2>
          <p className="text-xl text-gray-600 max-w-2xl mx-auto">
            Choose the plan that works best for your home search needs.
          </p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {plans.map((plan, index) => (
            <PricingCard key={index} plan={plan} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default PricingSection;
