import React, { useState, useEffect } from "react";
import CheckoutPage from "./CheckoutPage";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useSearchParams } from "react-router-dom";
import { useCaptchaVerification } from "../components/verifyCaptcha";
import LoadingScreen from '../components/LoadingScreen';
import { functions, httpsCallable } from '../firebase';
import { useAuth } from "../AuthContext"; // Add missing import
import { getDoc, doc } from 'firebase/firestore';
import { db } from '../firebase';

const STRIPE_PUBLIC_KEY = process.env.REACT_APP_ENV === "PROD" ? process.env.REACT_APP_STRIPE_PUBLIC_KEY_PROD : process.env.REACT_APP_STRIPE_PUBLIC_KEY

if (STRIPE_PUBLIC_KEY === undefined)
  throw new Error("Stripe public key is not defined");

const stripePromise = loadStripe(String(STRIPE_PUBLIC_KEY));

function SelectPlan() {
  const [searchParams] = useSearchParams();
  const [paymentStatus, setPaymentStatus] = useState(null);
  const captchaVerified = useCaptchaVerification();
  const [isPremium, setIsPremium] = useState(false);
  const [loading, setLoading] = useState(true);
  const { currentUser } = useAuth();

  useEffect(() => {
    const redirectStatus = searchParams.get("redirect_status"); // 'succeeded' or 'failed'
    if (redirectStatus) {
      setPaymentStatus(redirectStatus);
    }
  }, [searchParams]);

  useEffect(() => {
    if (!captchaVerified) {
      // If the CAPTCHA is not verified, you might want to display a loading indicator
      // or something that tells the user that CAPTCHA is being validated.
      console.log('Verifying CAPTCHA...');
    }
  }, [captchaVerified]);

  useEffect(() => {
    const checkPremiumStatus = async () => {
      try {
        const userDoc = await getDoc(doc(db, "users", currentUser.uid));
        const userData = userDoc.data();
        setIsPremium(userData?.isPremium || false);
      } catch (error) {
        console.error('Error checking premium status:', error);
      } finally {
        setLoading(false);
      }
    };

    if (currentUser) {
      checkPremiumStatus();
    }
  }, [currentUser]);

  const formatPrice = (amount) => {
    return new Intl.NumberFormat(navigator.language, {
      style: 'currency',
      currency: 'CHF'
    }).format(amount);
  };

  if (loading || !captchaVerified) {
    return <LoadingScreen />;
  }

  return (
    <div className="min-h-screen bg-gray-50 flex items-center justify-center p-4">
      <div className="flex flex-col items-center">
        <div className="bg-white rounded-xl shadow-2xl w-full max-w-5xl flex flex-col md:flex-row">
          {/* Payment Element Section */}
          <div className="w-full md:w-1/2 p-8 border-r border-gray-200">
            <Elements
              stripe={stripePromise}
              options={{
                mode: "payment",
                amount: isPremium ? 500 : 1000,
                currency: "chf",
              }}
            >
              <CheckoutPage amount={isPremium ? 500 : 1000} isPremium={isPremium} />
            </Elements>
          </div>

          {/* Summary Section */}
          <div className="w-full md:w-1/2 p-8">
            <h2 className="text-2xl font-bold mb-6">Order Summary</h2>
            <div className="space-y-6">
              <div>
                <p className="font-semibold mb-4">You are unlocking:</p>
                <ul className="list-none space-y-2">
                  {!isPremium ? (
                    <>
                      <li className="flex items-center">
                        <span className="mr-2">✓</span> 100 listings
                      </li>
                      <li className="flex items-center">
                        <span className="mr-2">✓</span> 3 Interest points
                      </li>
                      <li className="mt-4 text-sm text-gray-600">
                        You can later on get further listings and points of interest for {formatPrice(5)}
                      </li>
                    </>
                  ) : (
                    <>
                      <li className="flex items-center">
                        <span className="mr-2">✓</span> Extra 200 listings
                      </li>
                      <li className="flex items-center">
                        <span className="mr-2">✓</span> Extra 3 Interest points
                      </li>
                    </>
                  )}
                </ul>
              </div>

              <div className="border-t pt-4">
                <div className="flex justify-between items-center">
                  <span className="font-bold">Total:</span>
                  <span className="text-2xl font-bold text-blue-600">
                    {formatPrice(isPremium ? 5 : 10)}
                  </span>
                </div>
              </div>

              {paymentStatus === "succeeded" && (
                <div className="p-4 bg-green-100 text-green-700 rounded-lg">
                  <p>🎉 Your payment was successful! Thank you for your purchase.</p>
                </div>
              )}
              {paymentStatus === "failed" && (
                <div className="p-4 bg-red-100 text-red-700 rounded-lg">
                  <p>⚠️ Payment failed. Please try again.</p>
                </div>
              )}
            </div>
          </div>
        </div>
        <button
          onClick={() => window.location.href = "/dashboard"}
          className="mt-6 px-6 py-2 bg-gray-100 text-gray-700 rounded-lg hover:bg-gray-200 transition-colors"
        >
          Return to Dashboard
        </button>
      </div>
    </div>
  );
}

export default SelectPlan;
