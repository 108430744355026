
import { Button } from "../components/ui/button";
import { ArrowRight } from "lucide-react";

const HeroSection = () => {
  return (
    <section className="py-20 bg-gradient-to-br from-white to-blue-50">
      <div className="container px-4 md:px-6">
      <div className="flex flex-col items-center text-center space-y-8">
        <h1 className="text-4xl md:text-5xl lg:text-6xl font-bold tracking-tight text-gray-900 max-w-3xl animate-fade-in">
        Stop wasting time on frustrating home searches
        </h1>
        <p className="text-xl text-gray-600 max-w-2xl animate-fade-in" style={{ animationDelay: "100ms" }}>
        Say goodbye to endless browsing, long commutes, and confusing property listings. meuLar is your personal assistant that finds your perfect home faster and smarter.
        </p>
        <Button 
        onClick={() => window.location.href = '/login'}
        className="text-white bg-brand-blue hover:bg-blue-600 mt-4 px-8 py-6 text-lg animate-fade-in" 
        style={{ animationDelay: "200ms" }}
        >
        End The Home Search Struggle <ArrowRight className="ml-2 h-5 w-5" />
        </Button>
      </div>
      </div>
    </section>
    );
};

export default HeroSection;
