// App.js
import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Login from './pages/LoginPage';
import Dashboard from './pages/Dashboard';
import SelectPlan from './pages/SelectPlan';
import ProtectedRoute from "./ProtectedRoute";
import Landing from './pages/Landing';
import PrivacyRedirect from './components/PrivacyRedirect';

import { useAuth } from "./AuthContext";

const App = () => {
  const { currentUser } = useAuth();

  const [rateLimitError, setRateLimitError] = useState(null);

  useEffect(() => {
    const handleRateLimit = async () => {
      try {
        const response = await fetch(window.location.href);
        if (response.status === 429) {
          const data = await response.json();
          setRateLimitError(data.message);
        }
      } catch (error) {
        console.error('Rate limit check failed:', error);
      }
    };

    handleRateLimit();
  }, []);

  if (rateLimitError) {
    return (
      <div style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        flexDirection: 'column',
        gap: '20px'
      }}>
        <h2>Access Denied</h2>
        <p>{rateLimitError}</p>
      </div>
    );
  }

  return (
    <Router>
      <Routes>
        {/* Public route */}
        <Route path="/" element={!currentUser ? <Landing /> : <ProtectedRoute currentUser={currentUser}><Dashboard /></ProtectedRoute>} />
        <Route path="/login" element={!currentUser ? <Login /> : <ProtectedRoute currentUser={currentUser}><Dashboard /></ProtectedRoute>} />
        {/* Protected routes */}
        <Route
          path="/dashboard"
          element={
            <ProtectedRoute currentUser={currentUser}>
              <Dashboard />
            </ProtectedRoute>
          }
        />
        <Route
          path="/privacy/extension"
          element={<PrivacyRedirect />}
        />
        <Route
          path="/select-plan"
          element={
            <ProtectedRoute currentUser={currentUser}>
              <SelectPlan />
            </ProtectedRoute>
          }
        />
        {/* Catch-all route for unmatched paths */}
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </Router>
  );
};

export default App;
