
import React from "react";
import { Button } from "./ui/button";

const CtaSection = () => {
  return (
    <section className="py-16 bg-gradient-to-r from-blue-500 to-blue-600 text-white">
      <div className="container px-4 md:px-6">
        <div className="flex flex-col items-center text-center space-y-6">
          <h2 className="text-3xl md:text-4xl font-bold">
            Ready to End Your Home Search Frustrations?
          </h2>
          <p className="text-xl max-w-2xl opacity-90">
            Join thousands of happy homeowners who found their perfect home without the stress, wasted time, or endless property visits.
          </p>
          <Button
            onClick={() => window.location.href = '/login'}
            className="bg-white text-blue-600 hover:bg-gray-100 mt-4 px-8 py-6 text-lg"
            style={{ animationDelay: "200ms" }}
          >
            Start Finding Your Perfect Home

          </Button>
        </div>
      </div>
    </section>
  );
};

export default CtaSection;
