import React, { useEffect, useRef, useState } from "react";
import { MapContainer, TileLayer, Marker, Popup, useMap } from "react-leaflet";
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import 'leaflet.awesome-markers/dist/leaflet.awesome-markers.css';
import 'leaflet.awesome-markers';
import isEqual from "lodash/isEqual"; // Import lodash for deep comparison

const CenterAndZoomUpdater = ({ pointsOfInterest = {}, sortedEntries = [] }) => {
  const map = useMap();
  const prevDataRef = useRef({ pointsOfInterest: null, sortedEntries: null });

  useEffect(() => {
    if (!map) return;

    const hasDataChanged = () => {
      const prevPoints = prevDataRef.current.pointsOfInterest;
      const prevSorted = prevDataRef.current.sortedEntries;

      return (
        !isEqual(prevPoints, pointsOfInterest) ||
        !isEqual(prevSorted, sortedEntries)
      );
    };

    if (!hasDataChanged()) {
      return; // Skip if data hasn't changed
    }

    const bounds = L.latLngBounds();
    let hasValidBounds = false;

    // Add points of interest to bounds
    if (pointsOfInterest && Object.keys(pointsOfInterest).length > 0) {
      Object.values(pointsOfInterest).forEach((poi) => {
        if (poi?.geolocation?.lat && poi?.geolocation?.lon) {
          bounds.extend([poi.geolocation.lat, poi.geolocation.lon]);
          hasValidBounds = true;
        }
      });
    }

    // Add sorted entries to bounds
    if (sortedEntries && sortedEntries.length > 0) {
      sortedEntries.forEach(([_, entry]) => {
        if (entry?.geolocation?.lat && entry?.geolocation?.lon) {
          bounds.extend([entry.geolocation.lat, entry.geolocation.lon]);
          hasValidBounds = true;
        }
      });
    }

    if (hasValidBounds) {
      map.fitBounds(bounds, { padding: [50, 50], maxZoom: 16 });
    } else {
      // Default center if no valid bounds
      map.setView([51.505, -0.09], 13);
    }

    prevDataRef.current = { pointsOfInterest, sortedEntries };
  }, [map, pointsOfInterest, sortedEntries]);

  return null;
};

const MapClickHandler = ({ setIsFullscreen }) => {
  const map = useMap();
  
  useEffect(() => {
    if (!map) return;
    
    const handleClick = (e) => {
      if (e.originalEvent.target.classList.contains('leaflet-container')) {
        setIsFullscreen(prev => !prev);
        // Force map to recalculate size after animation
        setTimeout(() => {
          map.invalidateSize();
        }, 300);
      }
    };

    map.on('click', handleClick);
    return () => {
      map.off('click', handleClick);
    };
  }, [map, setIsFullscreen]);

  return null;
};

const MapComponent = ({ pointsOfInterest = {}, sortedEntries = [], mode = 'ranking' }) => {
  const [isFullscreen, setIsFullscreen] = useState(false);
  const getCustomIcon = (color) => {
    return L.AwesomeMarkers.icon({
      icon: "fa-home",
      markerColor: color,
      prefix: "fa",
      iconColor: "white",
    });
  };

  const mapStyle = {
    height: isFullscreen ? "80%" : "100%",
    width: isFullscreen ? "70vw" : "100%",
    position: isFullscreen ? "fixed" : "relative",
    top: isFullscreen ? "50%" : "auto",
    left: isFullscreen ? "50%" : "auto",
    transform: isFullscreen ? "translate(-50%, -50%)" : "none",
    zIndex: isFullscreen ? 9999 : "auto",
    transition: "all 0.3s ease-in-out",
    boxShadow: isFullscreen ? "0 0 20px rgba(0,0,0,0.3)" : "none",
    borderRadius: isFullscreen ? "8px" : "none",
  };

  const overlayStyle = {
    display: isFullscreen ? "block" : "none",
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0,0,0,0.5)",
    zIndex: 9998,
  };

  const closeButtonStyle = {
    display: isFullscreen ? "block" : "none",
    position: "fixed",
    top: "20px",
    right: "20px",
    zIndex: 10000,
    padding: "8px 12px",
    backgroundColor: "white",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
    boxShadow: "0 2px 4px rgba(0,0,0,0.2)",
  };

  return (
    <>
      <div className="map-overlay" style={overlayStyle} onClick={() => setIsFullscreen(false)} />
      <button 
        style={closeButtonStyle} 
        onClick={() => setIsFullscreen(false)}
      >
        Close Map
      </button>
      <div className="map-container" style={mapStyle}>
        <MapContainer
          center={[51.505, -0.09]} // Default center
          zoom={13} // Default zoom
          style={{ height: "100%", width: "100%", borderRadius: "inherit" }}
        >
          <MapClickHandler setIsFullscreen={setIsFullscreen} />
          <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />

          {/* Add CenterAndZoomUpdater */}
          <CenterAndZoomUpdater
            pointsOfInterest={pointsOfInterest}
            sortedEntries={sortedEntries}
          />

          {/* Render POIs */}
          {pointsOfInterest && Object.entries(pointsOfInterest).map(([key, poi]) => {
            if (poi?.geolocation?.lat && poi?.geolocation?.lon) {
              return (
                <Marker
                  key={`poi-${key}`}
                  position={[poi.geolocation.lat, poi.geolocation.lon]}
                  icon={L.AwesomeMarkers.icon({
                    icon: "fa-star",
                    markerColor: "purple",
                    prefix: "fa",
                    iconColor: "white",
                  })}
                >
                  <Popup>{poi.name || 'Point of Interest'}</Popup>
                </Marker>
              );
            }
            return null;
          })}

          {/* Render entries based on mode */}
          {sortedEntries && (mode === 'ranking' ? 
            // Ranking mode - top 4 with different colors
            sortedEntries.slice(0, 4).map(([id, entry], index) => {
              const colors = ["blue", "green", "orange", "red"];
              if (entry?.geolocation?.lat && entry?.geolocation?.lon) {
                return (
                  <Marker
                    key={`entry-${id}`}
                    position={[entry.geolocation.lat, entry.geolocation.lon]}
                    icon={getCustomIcon(colors[index])}
                  >
                    <Popup>{entry.Address || 'Property'}</Popup>
                  </Marker>
                );
              }
              return null;
            })
            : 
            // Import mode - up to 50 entries all in blue
            sortedEntries.slice(0, 50).map(([id, entry]) => {
              if (entry?.geolocation?.lat && entry?.geolocation?.lon) {
                return (
                  <Marker
                    key={`entry-${id}`}
                    position={[entry.geolocation.lat, entry.geolocation.lon]}
                    icon={getCustomIcon("blue")}
                  >
                    <Popup>{entry.Address || 'Property'}</Popup>
                  </Marker>
                );
              }
              return null;
            })
          )}
        </MapContainer>
      </div>
    </>
  );
};

export default MapComponent;
