import { useEffect } from 'react';

const PrivacyRedirect = () => {
  return (
    <iframe
      src="/PrivacyPolicy.html"
      style={{
        width: '100%',
        height: '100vh',
        border: 'none',
        position: 'fixed',
        top: 0,
        left: 0
      }}
      title="Privacy Policy"
    />
  );
};

export default PrivacyRedirect;
