
import React from "react";
import { Card, CardContent } from "./ui/card";
import { Star } from "lucide-react";

const TestimonialCard = ({ name, title, quote }) => {
  return (
    <Card className="border-none shadow-md hover:shadow-lg transition-shadow">
      <CardContent className="p-6">
        <div className="flex mb-4">
          {[...Array(5)].map((_, i) => (
            <Star key={i} className="h-5 w-5 fill-blue-600 text-blue-600" />
          ))}
        </div>
        <p className="text-gray-700 mb-6 italic">"{quote}"</p>
        <div>
          <p className="font-semibold text-gray-900">{name}</p>
          <p className="text-gray-600 text-sm">{title}</p>
        </div>
      </CardContent>
    </Card>
  );
};

const TestimonialsSection = () => {
  const testimonials = [
    /*{
      name: "John Doe",
      title: "Happy Homeowner",
      quote: "meuLar made my home search so much easier. I saved so much time and effort finding my perfect home.",
    },
    {
      name: "Sarah Smith",
      title: "First-time Buyer",
      quote: "As someone who was overwhelmed by the housing market, meuLar simplified everything for me.",
    },
    {
      name: "Michael Johnson",
      title: "Property Investor",
      quote: "The data-driven approach helped me make smarter investment decisions. Highly recommended!",
    },*/
  ];

  return (
    <section className="py-20 bg-gray-50">
      <div className="container px-4 md:px-6">
        <div className="text-center mb-12">
          <h2 className="text-3xl md:text-4xl font-bold text-gray-900 mb-4">
            What Our Users Say
          </h2>
          <p className="text-xl text-gray-600 max-w-2xl mx-auto">
            Don't just take our word for it. Here's what our satisfied users have to say.
          </p>
        </div>
        
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {testimonials.map((testimonial, index) => (
            <TestimonialCard
              key={index}
              name={testimonial.name}
              title={testimonial.title}
              quote={testimonial.quote}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default TestimonialsSection;
